html {
  scroll-behavior: smooth;
}

body {
  overflow: auto !important;
  padding: 0px !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
}

p {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.75rem;
}

.thin {
  font-weight: 100;
}

.bold {
  font-weight: bold;
}

@media (max-width: 426px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  h6 {
    font-size: 1rem;
  }
  p {
    font-size: 1.25rem;
  }
  input {
    font-size: 1.25rem;
  }
  button, .btn {
    font-size: 1.25rem;
  }
  th, td {
    font-size: 1.25rem;
  }
  /* override react-bootsrap form css */
  .modal-body {
    overflow-y: scroll;
  }
  .form-control {
    font-size: 1.5rem;
  }
  .modal-header {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .modal-header .close {
    padding: 0;
    margin: unset;
    line-height: 2rem;
  }
  .modal-header .close span {
    font-size: 2rem;
  }
}

.hide {
  display: none;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 1023px) {
  .flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.wht {
  color: white;
}

.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.abs.four, .rel.four {
  z-index: 4;
}

.abs.three, .rel.three {
  z-index: 3;
}

.abs.two, .rel.two {
  z-index: 2;
}

.abs.one, .rel.one {
  z-index: 1;
}

.abs.-one, .rel.-one {
  z-index: -1;
}

.abs.-two, .rel.-two {
  z-index: -2;
}

.abs.-three, .rel.-three {
  z-index: -3;
}

.abs.-four, .rel.-four {
  z-index: -4;
}

.container {
  margin: 150px auto;
  height: 100%;
  width: 1000px;
  max-width: 90%;
}

.content {
  position: relative;
  width: 100%;
  max-width: 600px;
}

@media (max-width: 426px) {
  .contentSubHeader {
    font-size: 1.25rem;
  }
}

.centered {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.textCentered {
  text-align: center;
}

.blue {
  color: #0d7691;
  background-color: #0d7691;
}

.green {
  color: #22916f;
  background-color: #22916f;
}

.dark {
  color: #232d33;
}

.bgDark {
  background-color: #232d33;
}

.gradient {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#0d7691), to(#22916f));
  background-image: linear-gradient(#0d7691, #22916f);
}

.noMargin {
  margin: 0;
}

/* -------------------- modal/forms -------------------- */
.modal {
  padding: 0 !important;
}

@media (max-width: 426px) {
  .modal {
    overflow: hidden !important;
    z-index: 2001;
  }
  .modalForm {
    margin: 0;
  }
  .modal-content {
    border: none;
    border-radius: 0;
    height: 100vh;
  }
}

.form-1 input, .form-1 input:focus, .form-1 textArea, .form-1 textArea:focus {
  background-color: transparent;
  border: white solid 2px;
  color: white;
}

.form-1 input::-webkit-input-placeholder, .form-1 input:focus::-webkit-input-placeholder, .form-1 textArea::-webkit-input-placeholder, .form-1 textArea:focus::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.form-1 input:-ms-input-placeholder, .form-1 input:focus:-ms-input-placeholder, .form-1 textArea:-ms-input-placeholder, .form-1 textArea:focus:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.form-1 input::-ms-input-placeholder, .form-1 input:focus::-ms-input-placeholder, .form-1 textArea::-ms-input-placeholder, .form-1 textArea:focus::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.form-1 input::placeholder, .form-1 input:focus::placeholder, .form-1 textArea::placeholder, .form-1 textArea:focus::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.form-1 .btn {
  border-width: 2px;
  font-weight: 500;
  padding: 0.25rem 0.75rem 0.5rem 0.75rem;
}

@media (max-width: 426px) {
  .form-1 .btn {
    font-size: 1.5rem;
  }
}

.form-1 .btn:hover {
  font-weight: 500;
  color: #0d7691;
}

input {
  border: #bdbdbd 1px solid;
  border-radius: 0.25rem;
}

.invalid {
  border: red 1px solid;
  border-radius: 0.25rem;
}

/* -------------------- videoBG -------------------- */
.player-wrapper {
  height: 70vh;
  overflow: hidden;
  -webkit-filter: brightness(0.4);
          filter: brightness(0.4);
}

.react-player {
  position: absolute;
  width: 100%;
}

@media (max-width: 1023px) and (min-width: 426px) and (orientation: portrait) {
  .react-player {
    position: absolute;
    top: 0;
    right: -30%;
    height: 100%;
    width: unset;
  }
}

@media (max-width: 426px) and (orientation: portrait) {
  .react-player {
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: unset;
  }
}
/*# sourceMappingURL=App.css.map */